import { reactive, ref } from '@vue/composition-api'
import router from '@/router'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useInvoices() {
  const { errorToast, successToast } = useAlertNotification()

  const invoice = ref([])
  const loader = ref(false)
  const statistiquesInvoices = reactive({
    facturesRecues: 0,
    facturesGenerees: 0,
  })
  const invoiceProcess = ref(false)
  const invoiceSuccess = ref(false)
  const invoices = ref([])
  const errors = ref('')

  // Liste des invoices
  const getInvoicesByTransporter = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/list-invoice-transporter')
      if (response.data.success === true) {
        loader.value = false
        invoices.value = response.data.data
        statistiquesInvoices.facturesGenerees = response.data.data.length
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getInvoicesByAffreteur = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/list-invoice-affreteur')
      if (response.data.success === true) {
        loader.value = false
        invoices.value = response.data.data
        statistiquesInvoices.facturesRecues = response.data.data.length
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Obtenir une invoice
  const getInvoice = async () => {
    const response = await axiosClient.get(`/get-invoice-by-code/${router.currentRoute.params.code}`)
    invoice.value = response.data.data
  }
  // Ajouter une invoice
  const createOrUpdateInvoice = async data => {
    try {
      errors.value = ''
      invoiceProcess.value = true
      const response = await axiosClient.post('/store-or-update-invoice', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        invoiceSuccess.value = true
        invoiceProcess.value = false
        // await getInvoices()
      }
    } catch (error) {
      invoiceProcess.value = false
      invoiceSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const createUserErrors = error.response.data.errors
      Object.keys(createUserErrors).forEach(key => {
        errorToast('Oups! Erreur', createUserErrors[key][0])
      })
    }
  }
  const generateInvoice = async data => {
    try {
      errors.value = ''
      invoiceProcess.value = true
      const response = await axiosClient.post('/generate-invoice', data)
      if (response.data.success === true) {
        invoiceSuccess.value = true
        invoiceProcess.value = false
        successToast('Enregistrement', response.data.message)

        // await getInvoices()
      }
    } catch (error) {
      invoiceProcess.value = false
      invoiceSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const createUserErrors = error.response.data.errors
      Object.keys(createUserErrors).forEach(key => {
        errorToast('Oups! Erreur', createUserErrors[key][0])
      })
    }
  }
  const sendPDF = async data => {
    try {
      errors.value = ''
      invoiceProcess.value = true
      const response = await axiosClient.post('/send-invoice-notification', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        invoiceSuccess.value = true
        invoiceProcess.value = false
        await getInvoicesByTransporter()
      }
    } catch (error) {
      invoiceProcess.value = false
      invoiceSuccess.value = false
      loader.value = false
      errors.value = ''
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createUserErrors = error.response.data.errors
        Object.keys(createUserErrors).forEach(key => {
          errorToast('Oups! Erreur', createUserErrors[key][0])
        })
      }
    }
  }

  return {
    errors,
    invoice,
    invoices,
    getInvoicesByTransporter,
    generateInvoice,
    invoiceProcess,
    createOrUpdateInvoice,
    getInvoice,
    sendPDF,
    getInvoicesByAffreteur,
    statistiquesInvoices,
    loader,
    invoiceSuccess,
  }
}
