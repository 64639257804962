import { ref } from '@vue/composition-api'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useDevises() {
  const { errorToast, successToast } = useAlertNotification()

  const devise = ref([])
  const loader = ref(false)
  const deviseProcess = ref(false)
  const deviseSuccess = ref(false)
  const devises = ref([])
  const errors = ref('')

  // Liste des devises
  const getDevises = async () => {
    loader.value = true
    await axiosClient.get('/devises').then(response => {
      if (response.data.success === true) {
        loader.value = false
        devises.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une devise
  const getDevise = async slug => {
    const response = await axiosClient.get(`/devises/${slug}`)
    devise.value = response.data.data
  }
  // Ajouter une devise
  const createDevise = async data => {
    errors.value = ''
    console.log(data)
    deviseProcess.value = true
    await axiosClient.post('/devises', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          deviseSuccess.value = true
          deviseProcess.value = false
          getDevises()
        }
      })
      .catch(error => {
        deviseProcess.value = false
        deviseSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une devise
  const updateDevise = async data => {
    errors.value = ''
    deviseProcess.value = true
    await axiosClient.patch(`/devises/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          deviseSuccess.value = true
          deviseProcess.value = false
          getDevises()
        }
      })
      .catch(error => {
        deviseProcess.value = false
        deviseSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    devise,
    devises,
    getDevises,
    deviseProcess,
    createDevise,
    updateDevise,
    getDevise,
    loader,
    deviseSuccess,
  }
}
